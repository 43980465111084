@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Rasa:ital@0;1&family=Source+Serif+Pro:ital@0;1&display=swap');

/*
font-family: 'Lato', sans-serif;
font-family: 'Rasa', serif;
font-family: 'Source Serif Pro', serif;
*/

html {
    background-color: #FFFBEE;

}

.App {
    text-align: center;
    background-color: #FFFBEE;
    align: center;
    /*line-height: 1.4;*/
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    padding: 25px 0 0;
    margin-bottom: 0;
    font-family: 'Source Serif Pro', serif;
}

.results-pad {
    padding-bottom: 20px;
}

.footer {
    padding: 25px 0 20px;
    margin: 0 auto;
    width: 95%;
    font-family: 'Source Serif Pro', serif;
}

.App-header > h2 {
}

.App-logo {
    height: 40px;
    padding-right: 8px;
}

.App-link {
    color: #61dafb;
}

/* Styles for wrapping the search box */

.main {
    width: 50%;
    margin: 50px auto;
}

.page {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    padding: 0px;
    background-color: #FFFBEE;
    filter: drop-shadow(0px 0px 7px #00000033);
    max-width: 800px;
    width: 90%;
    min-height: 88vh;
}

.input-wrapper {
    font-family: 'Lato', sans-serif;
    font-size: 22px;
    margin-top: 30px;
    margin-bottom: 10px;
    width: 90%;
    /*background-color: #e9ecef;*/
    /*border: 1px solid #ced4da;*/
}

.input-box {
    filter: drop-shadow(0px 0px 4px #00000022);
    font-size: 18px !important;
    margin: 15px 10px 15px;
    overflow: hidden;
    resize: none;
}

/* Lexeme Logo. Grayed out when enableNaturalAnswer is false. */
.lexeme-logo-checkbox {
    height: 1.2em;
    cursor: pointer;
/*    rounded corners */
    border-radius: 10%!important;
    margin: 30px 8px 0 auto;
}

.lexeme-logo-checkbox.grayed-out {
    filter: grayscale(100%);
}

.lexeme-take-tooltip {
    padding: 5px;
}

.loading {
    background-color: #ffffff;
    background-image: url('../public/loading.gif');
    background-size: 25px 25px;
    background-position: 99% center;
    background-repeat: no-repeat;
}

.results-wrapper {
    width: 92.5%;
    margin: 0 auto;
}

.natural-answer-wrapper {
    width: 92.5%;
    margin: 0 auto;
}

.lexeme-logo {
    height: 1.2em;
    padding-right: 8px;
    padding-bottom: 5px;
}

.natural-answer-card {
    font-family: 'Source Serif Pro', serif;
    padding: 10px;
    text-align: left;
    background-color: #ededed !important;
    margin: 10px;
    filter: drop-shadow(0px 0px 4px #00000088);
}

.natural-answer-title {
    font-weight: bold;
    font-style: italic;
    font-size: 18px;
    color: black;
}

.natural-answer-text {
    font-family: 'Source Serif Pro', serif;
    margin: 4px 0 5px 0;
    font-size: 18px;
    color: black;
}

.natural-answer-buttons {
    /* Right-justified */
    padding-right: 12px;
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    user-select: none;
}

.natural-answer-button {
    cursor: pointer;
}

.helpful-yes.filled {
    color: green;
}

.helpful-no.filled {
    color: darkred;
}

a.recommended-question-link {
    /*  Style as pill shaped button */
    background-color: #FFFBEE;
    border: 1px solid #000000;
    border-radius: 20px;
    color: #000000;
    padding: 5px 10px;
    /*text-align: center;*/
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 2px;
    cursor: pointer;
    filter: drop-shadow(0px 0px 4px #00000022);
}

.results-card {
    font-family: 'Lato', sans-serif;
    padding: 10px;
    text-align: left;
    background-color: #FFFFFF;
    margin: 10px;
    filter: drop-shadow(0px 0px 2px #00000022);
}

.results-title {
    font-weight: bold;
    font-size: 18px;
    color: #444;
}

.results-subtitle {
    font-size: 16px;
    color: #777;
}

.results-text {
    font-family: 'Source Serif Pro', serif;
    margin-top: 20px;
    font-size: 18px;
    color: #555;
}

a.results-link {
    text-decoration: none;
}
